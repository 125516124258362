var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-row tree-header py-3"},_vm._l((_vm.tableHeaders),function(header){return _c('div',{key:header.id,class:header.className,style:(header.width
          ? {
              width: ((header.width) + "px"),
            }
          : undefined)},[_vm._v(" "+_vm._s(header.text)+" ")])}),0),_c('v-treeview',{attrs:{"items":_vm.treeItems,"open":_vm.treeOpen,"expand-icon":"mdi-chevron-down"},scopedSlots:_vm._u([{key:"label",fn:function(ref){
          var item = ref.item;
return [(item.documentKinds || item.subsections)?_c('div',{staticClass:"tree-view__wrap"},[_c('div',{key:item.id,staticClass:"tree-view__label"},[_c('div',{staticClass:"tree-view__item"},[_vm._v(_vm._s(item.name))])])]):_c('div',{key:item.id,staticClass:"d-flex flex-column tree-view__item-wrap py-2"},[_c('div',{staticClass:"d-flex flex-row"},_vm._l((_vm.tableHeaders),function(header,index){return _c('div',{key:header.id,class:("tree-view__item " + (!index ? 'flex flex-grow-1' : '') + " px-2"),style:(header.width
                ? {
                    'min-width': ((header.width) + "px"),
                    width: ((header.width) + "px"),
                  }
                : undefined)},[(header.value === 'storageTermValue')?_c('div',[_vm._v(_vm._s(_vm.storageTermsObj[item.storageTermValue] || ""))]):_c('span',[_vm._v(_vm._s(item[header.value]))])])}),0),_vm._l((item.storageTermList || []),function(subitem){return _c('div',{key:subitem.id,staticClass:"d-flex flex-row"},_vm._l((_vm.tableHeaders),function(header,index){return _c('div',{key:header.id,class:("tree-view__item " + (!index ? 'flex flex-grow-1' : '') + " px-2"),style:(header.width
                ? {
                    'min-width': ((header.width) + "px"),
                    width: ((header.width) + "px"),
                  }
                : undefined)},[(header.value === 'storageTermValue')?_c('div',[_vm._v(_vm._s(_vm.storageTermsObj[subitem.storageTermValue] || ""))]):(header.value === 'name')?_c('div',{staticClass:"pl-8"},[_vm._v(_vm._s(subitem.storageTermKey || ""))]):_c('span',[_vm._v(_vm._s(subitem[header.value]))])])}),0)})],2)]}}]),model:{value:(_vm.selectedTreeItems),callback:function ($$v) {_vm.selectedTreeItems=$$v},expression:"selectedTreeItems"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }