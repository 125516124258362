





































































import { defineComponent } from "@vue/composition-api";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { IDocSection } from "@monorepo/catalog/src/views/DocTypeView/types/docTypeElement";
import { convertApiTreeItemToUi } from "@monorepo/catalog/src/views/DocTypeView/utils/convertApiItemToUi";
import { v4 as uuid } from "uuid";

const tableHeaders = [
  {
    text: "Вид документа",
    value: "name",
    className: "flex flex-grow-1 pl-10",
    id: uuid(),
  },
  {
    text: "Статья",
    width: 100,
    value: "article",
    id: uuid(),
  },
  {
    text: "Срок хранения",
    width: 180,
    value: "storageTermValue",
    id: uuid(),
  },
  {
    text: "Комментарий",
    width: 220,
    value: "comment",
    id: uuid(),
  },
];

export default defineComponent({
  name: "DocumentTreeRead",
  data() {
    return {
      selectedItems: "",
      dialogVisible: false,
      treeOpen: [],
      treeItems: [] as IDocSection[],
      selectedTreeItems: [],
      draggedDocId: "",
      draggedSectionId: "",
      draggedOverSeparatorId: "",
      isNeedSaveIds: {} as Record<string, boolean>,
      isNeedSaveDocIds: {} as Record<string, boolean>,
      isSaveLoadingIds: {} as Record<string, boolean>,
      tableHeaders,
    };
  },
  computed: {
    ...mapGetters("docTypeView", ["infiniteId", "storageTerms"]),
    storageTermsObj() {
      return ((this.storageTerms as unknown as Record<string, string>[]) || []).reduce(
        (result: Record<string, string>, current: Record<string, string>) => {
          result[current.id] = current.value;
          return result;
        },
        {}
      );
    },
    isDroppableSection() {
      return (item: IDocSection) => {
        return (
          (item.documentKinds?.length && this.draggedDocId && !item.documentKinds.find((doc) => doc.id === this.draggedDocId)) ||
          (item.subsections?.length &&
            this.draggedSectionId &&
            item.id !== this.draggedSectionId &&
            !item.subsections.find((sub) => sub.id === this.draggedSectionId)) ||
          (!item.documentKinds?.length && !item.subsections?.length)
        );
      };
    },
    isNeedSave() {
      return ({ parentId }: { parentId: number | null }, isDocument?: boolean) => {
        if (isDocument) {
          return this.isNeedSaveDocIds[parentId || "root"];
        }
        return this.isNeedSaveIds[parentId || "root"];
      };
    },
    isSaveLoading() {
      return ({ parentId }: { parentId: number | null }) => {
        return this.isSaveLoadingIds[parentId || "root"];
      };
    },
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapMutations("docTypeView", ["setSelectedIds", "setData"]),
    ...mapActions("docTypeView", [
      "getDocTypeHierarchy",
      "refreshScroll",
      "deleteElement",
      "transferDocument",
      "transferSection",
      "addNewSection",
      "addNewDocument",
    ]),
    getTreeItems() {
      const params = {
        type: this.$route.query.type,
        versionId: this.$route.query.versionId,
      };
      this.getDocTypeHierarchy(params).then((result: IDocSection[]) => {
        if (result) {
          this.treeItems = ((result as IDocSection[]) || [])
            .sort((a, b) => a.orderIndex - b.orderIndex)
            .map((item) => convertApiTreeItemToUi(item, false, true));
        }
      });
    },
  },
  watch: {
    infiniteId: {
      immediate: true,
      handler() {
        this.getTreeItems();
      },
    },
  },
});
